import { axiosPost, axiosGet, axiosPut, axiosDelete } from './config'

/**
 * 1:1문의 리스트를 페이지 단위로 가져오기
 **/
export const qna_list = (curPage = 1, rowPerPage = 20, searchType = 'answer', searchText = 'N') => {
  // http://{{adm}}/adm_api/operation/qna?curPage=1&rowPerPage=30
  var url = `operation/qna?curPage=${curPage}&rowPerPage=${rowPerPage}`
  if (searchText) url += `&searchText=${searchText}`
  if (searchType) url += `&searchType=${searchType}`

  return axiosGet(url)
}

/**
 * 1:1문의 상세 가져오기(관리자용)
 **/
export const qna_detail = qna_sn => {
  // http://{{adm}}/adm_api/operation/qna/1
  var url = `operation/qna/${qna_sn}`
  return axiosGet(url)
}

/**
 * 1:1문의 답변달기(관리자용)
 *
 * @param qna_sn
 * @param answer
 * **/
export const qna_set_answer = (qna_sn, answer, user_sn) => {
  var url = `operation/qna`
  // axiosPost('qna_set_answer', {
  //   qna_sn,
  //   answer
  // }).then(response => {
  //   if (response.result_code !== '__OK__') {
  //     console.error(' 실패', response.result_code)
  //   } else {
  //     alert('답변완료' + response.result_code)
  //   }
  // })
  return axiosPut(url, { qna_sn, answer, user_sn })
}
/**
 * 공지 리스트를 페이지 단위로 가져오기
 **/
export const getNoticeList = (curPage = 1, rowPerPage = 30) => {
  var url = `operation/notice?curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url, {
    curPage,
    rowPerPage
  })
}

export const getNoticeCode = () => {
  var url = 'comm/code/124'
  return axiosGet(url)
}

/**
 * 공지사항 등록
 * @param {*} notice_type
 * @param {*} title
 * @param {*} contents
 * @returns
 */
export const createNotice = (notice_type, title, contents, store_cds) => {
  var url = 'operation/notice'
  return axiosPost(url, { notice_type, title, contents, store_cds })
}

/**
 * 공지사항 상세 정보
 * @param {*} notice_sn
 */
export const getNoticeDetail = notice_sn => {
  var url = `operation/notice/${notice_sn}`
  return axiosGet(url)
}

export const updateNotice = (notice_sn, notice_type, title, contents, store_cds) => {
  var url = `operation/notice`
  return axiosPut(url, { notice_sn, notice_type, title, contents, store_cds })
}

export const deleteNotice = notice_sn => {
  var url = `operation/notice`
  return axiosDelete(url, { notice_sn })
}

/**
 * 공지사항 푸시
 * @param {*} notice_type
 * @param {*} title
 * @param {*} contents
 * @returns
 */
export const pushNotice = (notice_type, title, notice_sn) => {
  var url = 'operation/notice/push'
  var push_type = notice_type == '02' ? 'EVENT' : notice_type == '03' ? 'NOTICE' : 'ETC'
  return axiosPost(url, { push_type, title, notice_sn })
}

/**
 * 푸시 리스트를 페이지 단위로 가져오기
 **/
export const getPushList = (curPage = 1, rowPerPage = 30, notice_sn = 0, user_sn = 0) => {
  var url = `operation/push?curPage=${curPage}&rowPerPage=${rowPerPage}&notice_sn=${notice_sn}&user_sn=${user_sn}`
  return axiosGet(url, {
    curPage,
    rowPerPage
  })
}

/**
 * QnA 코드 가져오기
 * @returns
 */
export const getQNACode = () => {
  var url = `comm/code/125`
  return axiosGet(url)
}

/**
 * FAQ 리스트 가져오기
 * @param {*} curPage
 * @param {*} rowPerPage
 * @returns
 */
export const getFAQList = (curPage = 1, rowPerPage = 30) => {
  var url = `operation/faq?curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url, {
    curPage,
    rowPerPage
  })
}

/**
 * FAQ에서 사용하는 코드 가져오기
 * @returns
 */
export const getFAQCode = () => {
  var url = 'comm/code/125'
  return axiosGet(url)
}

/**
 * FAQ 상세내용 가져오기
 * @param {*} faq_sn
 * @returns
 */
export const getFAQDetail = faq_sn => {
  var url = `operation/faq/${faq_sn}`
  return axiosGet(url)
}

/**
 * FAQ 등록
 * @param {*} faq_type
 * @param {*} title
 * @param {*} contents
 * @returns
 */
export const createFAQ = (faq_type, title, contents, store_cds) => {
  var url = 'operation/faq'
  return axiosPost(url, { faq_type, title, contents, store_cds })
}

/**
 * FAQ 수정
 * @param {*} faq_sn
 * @param {*} faq_type
 * @param {*} title
 * @param {*} contents
 * @returns
 */
export const updateFAQ = (faq_sn, faq_type, title, contents, store_cds) => {
  var url = `operation/faq`
  return axiosPut(url, { faq_sn, faq_type, title, contents, store_cds })
}

/**
 * FAQ 삭제
 * @param {*} faq_sn
 * @returnsfaq_sn
 */
export const deleteFAQ = faq_sn => {
  var url = `operation/faq`
  return axiosDelete(url, { faq_sn })
}

/**
 * 고객이 등록한 리뷰 가져오기
 * @param {*} curPage
 * @param {*} rowPerPage
 */
export const getReview = (curPage = 1, rowPerPage = 20) => {
  //http://{{adm}}/adm_api/operation/review?curPage=1&rowPerPage=20
  var url = `operation/review?curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url)
}

/**
 * 메인영상 리스트를 페이지 단위로 가져오기
 **/
export const getMainList = (curPage = 1, rowPerPage = 30) => {
  var url = `operation/main?curPage=${curPage}&rowPerPage=${rowPerPage}`
  return axiosGet(url, {
    curPage,
    rowPerPage
  })
}

/**
 * 메인영상 등록
 * @param {string} lc_no
 * @param {string} category
 * @returns
 */
export const createMain = (lc_no, main_type, category, tag1, tag2, tag3, tag4, tag5) => {
  var url = 'operation/main'
  return axiosPost(url, { lc_no, main_type, category, tag1, tag2, tag3, tag4, tag5 })
}

export const deleteMain = (lc_no, main_type) => {
  var url = `operation/main`
  return axiosDelete(url, { lc_no, main_type })
}

/**
 * 문자메시지 전송
 * @param {*} hpnos
 * @param {*} target
 * @param {*} msg
 * @param {*} subject
 * @returns
 */
export const sendLMS = (hpnos, target, msg, subject, sendDate = null, filePath = null) => {
  let url = `user/lms`
  if (sendDate) {
    return axiosPost(url, { hpnos, target, msg, subject, sendDate, filePath })
  } else {
    return axiosPost(url, { hpnos, target, msg, subject, filePath })
  }
}

// sendMail(this.target_type, this.contents, this.subject, dt)
export const sendMail = (type, content, subject, sendDate, targets, words) => {
  return axiosPost('operation/mail', {
    subject,
    content,
    targetType: type,
    targets,
    words,
    sendDate
  })
}

/**
 * 댓글 리스트를 페이지 단위로 가져오기
 **/
export const comment_list = (curPage = 1, rowPerPage = 20, searchType, searchText) => {
  var url = `operation/comments?curPage=${curPage}&rowPerPage=${rowPerPage}`
  if (searchText) url += `&searchText=${searchText}`
  if (searchType) url += `&searchType=${searchType}`

  return axiosGet(url)
}

export const comment_accuse_list = co_sn => {
  var url = `operation/comment/${co_sn}/accuse`
  return axiosGet(url)
}

export const comment_delete = co_sn => {
  var url = `operation/comment/${co_sn}`
  return axiosDelete(url)
}

/**
 * 메일 클릭 리스트
 */
export const mail_click_list = (curPage = 1, rowPerPage = 20, startDate, endDate) => {
  var url = `operation/mail_click?curPage=${curPage}&rowPerPage=${rowPerPage}&startDate=${startDate}&endDate=${endDate}`
  return axiosGet(url)
}

/**
 * 이베스트 세미나 이벤트
 */
export const seminar_group = () => {
  var url = `operation/seminar_group`
  return axiosGet(url)
}
export const seminar_list = event_cd => {
  var url = `operation/seminar/${event_cd}`
  return axiosGet(url)
}
export const seminar_delete = (event_cd, selected = {}) => {
  var url = `operation/seminar/${event_cd}`
  return axiosDelete(url, selected)
}

/**
 * 이벤트 신청 리스트를 페이지 단위로 가져오기
 **/
export const event_list = (curPage = 1, rowPerPage = 20, searchType, searchText) => {
  var url = `operation/event?curPage=${curPage}&rowPerPage=${rowPerPage}`
  if (searchText) url += `&searchText=${searchText}`
  if (searchType) url += `&searchType=${searchType}`

  return axiosGet(url)
}
